.AllPhotos-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    flex-wrap: wrap;
    margin-top: 20px;
    border-radius: 5px 5px;

}


.Photo-Container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page {
    display: none;
}

.active-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    flex-wrap: wrap;
    margin-top: 20px;
    border-radius: 5px 5px;
}

.page-button {
    border: none;
    background-color: transparent;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 5px 5px;

    font-family: sans-serif;
    transition-duration: .5s;


}

.page-button:hover {
    cursor: pointer;
    font-weight: bold;
}
.active-button{
    border: none;
    font-weight: bold;

    border-radius: 5px;
    margin: 5px 5px;
    font-family: sans-serif;
    transition-duration: .5s;
    font-weight: bold;
}

.button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 45px;
}


.Full-Container{
    margin-top: 100px;
    /* background-color: rgb(54, 54, 54, .5 ); */
}

.button-page-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.pageTurner-Button{

    padding: 20px 50px;
    background-color: transparent;
    border: none;
    font-size: 30px;
    font-weight: bold;
    transition-duration: .3s;
}
.pageTurner-Button:hover{
    cursor: pointer;
    /* border: 2px solid black; */
    border-radius: 5px;
    background-color: #258e46;
    box-shadow: 0px 0px 10px black;
    color: white;
}
