.Full-About-Container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AboutJLD-Container {
  border-bottom: #006837 solid 10px;
  background-color: #258e46;
  display: flex;
  flex-direction: row;
}

.state_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  background-color: white;
  border: solid rgb(228, 228, 228) 2px;
  border-radius: 5px;
  margin: 10px;
  color: #173c34;
  padding: 20px;
  transition-duration: .3s;
  height: 300px;
}
.state_container:hover {
  cursor: pointer;
  background-color: #258e46;
  border: solid #006837 2px;
  color: white;
}

.x-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AboutUs-stage-span{
  font-weight:600;
}

.twoDImage{
  max-width: 400px;
  margin-right: 100px;
}

.threeImage{
  max-width: 600px;
}
.y-container{
  display: flex;
  flex-direction: row;
}

.bottom-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-around;
  padding-bottom:10px;
}

.phoneImage{
  display: none;
}
@media  screen and (width <= 900px) {

  .x-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aboutPageImage{
    display: none;
  }

  .phoneImage{
    display: flex;
    max-width: 100vw;
  }

  .bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .twoDImage{
    max-width: 100vw;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .threeImage{
    max-width: 100vw;
  }
}
