.JLDLogo {
  width: 100px;
  margin: 10px;
  margin-left: 15px;
  padding-right: 15px;
  border-right: solid 3px #173c34;
}

.left-containers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.jasmineLandscapingDesign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-weight: bold;
  font-family: didot;
  color: #173c34;
  font-size: 30px;
}
.navbar-container {
  display: flex;
  flex-direction: row;
  border-bottom: solid 9px #006837;
  position: fixed;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  top: 0;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  z-index: 10;
  padding: 5px;
}

.right-container {
  display: flex;
  margin: 10px 10px;
}
/*
f2f2f2
e6e6e6 */

.AboutUs-Container {
  margin-left: 10px;
  transition-duration: 0.8s;
  text-decoration: none;
}

.nav-tag {
  text-decoration: none;
  transition-duration: 0.3s;
  color: #173c34;
  font-weight: bold;
  padding: 10px;
}

.nav-tag:hover {
  border-bottom: 4px solid #258e46;
  /* border-bottom-radius: 20px; */
  color: #258e46;
}

.instaLogo {
  width: 30px;
  margin-top: 10px;
  margin-right: 10px;
}

.sidebar-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar-toggle-logo {
  font-size: 2.3rem;
  color: black;
  cursor: pointer;
  border:none;
  background-color: transparent;
}

.sidebar {
  width: 16rem;
  background: #1b1919;
  /* height: 90vh; */
  position: absolute;
  top: 100px;
  left: -100%;
  transition: 0.3s all ease-in-out;
  z-index: 4;
}

.sidebar.active {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  left: 0;
  position:fixed;
  background-color: #1b1919;
}

.sidebar-items {
  font-size: 1.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.2s all ease-out;
  padding-left: 0px;
}

.sidebar-item {
  list-style: none;
  margin-top: 1rem;
  width: 100%;
  height: 3rem;
}

.sidebar-item a {
  text-decoration: none;
  color: whitesmoke;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-out;
}

.sidebar-item a span {
  margin-left: 1.5rem;
  font-size: 1.4rem;
}

.sidebar-item a:hover {
  background: white;
  color: #173c34;
  font-weight: bold;
}

.sidebar .btn {
  margin-top: 2.4rem;
  width: 100%;
}

@media screen and (width <= 900px) {
  .sidebar.active {
    width: 10rem;
    padding: 20px;
    height: 100vh;
  }

  .sidebar-item a span {
    display: none;
  }
}

@media screen and (width <= 611px) {
  .jasmineLandscapingDesign {
    display: none;
  }
}

@media screen and (width <= 414px) {
  .sidebar-toggle {
    /* margin-right: 50px; */
  }
}
