.contactLogo {
  width: 20px;
  padding-top: 1;
}

.Logo-Container {
  display: flex;
  flex-direction: column;
  width: 20px;
}

.contact-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email-logo {
  display: flex;
  align-items: center;
}

.instagram-logo {
  display: flex;
  align-items: center;
}

.email-ptag {
  font-size: 13px;
  text-decoration: none;
  color: rgb(112, 112, 112);
  transition-duration: 0.3s;
  font-weight: bold;
  margin-top: 10px;
}
.email-ptag:hover {
  padding-left: 10px;
  color: rgb(212, 212, 212);
}
.instagram-ptag {
  font-size: 13px;
  text-decoration: none;
  color: rgb(112, 112, 112);
  transition-duration: 0.3s;
  font-weight: bold;
  margin: 0;
}

.instagram-ptag:hover {
  padding-left: 10px;
  color: rgb(212, 212, 212);
}

.link-p {
  text-decoration: none;
}
.JLD-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Contact-Us-Container {
  padding-left: 10px;
  max-width: fit-content;
}

.Footer-Container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(27, 27, 27);
  padding-bottom: 30px;
}

.footer-links {
  text-decoration: none;
  padding-top: 10px;
  font-size: 13px;
  font-weight: bold;
  transition-duration: 0.3s;
  color: rgb(112, 112, 112);
}

.footer-links:hover {
  color: rgb(212, 212, 212);
  padding-left: 10px;
}

.left-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Support-Container {
  display: flex;
  flex-direction: column;
}

.font-link {
  display: flex;
  flex-direction: column;
  margin: 0px;
  color: rgb(212, 212, 212);
  margin-top: 0px;
  /* border-bottom: 2px solid green; */
}

.copyright {
  display: flex;
  flex-direction: column;
  font-size: 8px;
  margin: 0;
  color: rgb(212, 212, 212);
}

.copyright-Container {
  margin-top: 10px;
}

.Jasmine-Landscaping-Container {
  display: flex;
  flex-direction: column;
}

.Social-Container {
  display: flex;
  flex-direction: column;
}

.foot-container {
  margin-left: 40px;
}

.footboottest {
  color: white;
}

@media screen and (width <= 600px) {
  .Footer-Container {
    display: flex;
    flex-direction: col;
    width: 100vw;
  }
  .left-container{
    display: flex;
    flex-direction: column;
  }

  .foot-container{
    margin-bottom: 10px;
  }
}
