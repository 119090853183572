body.active-modl {
  overflow-y: hidden;
}
.photo {
  max-width: 300px;
  max-height: 300px;
  margin: 10px 10px;
  border-radius: 5px;
}

.photo:hover {
  cursor: pointer;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}


.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 50px 50px;
  border-radius: 3px;
  max-width: 1000px;
  min-width: 300px;
  margin-top: 150px;
}
.overlay {
    background-color: rgb(128, 128, 128, 0.8);
}

.modal-picture {
    max-width: 900px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #173c34;
  color: white;
  border: none;
  transition-duration: 0.4s;
}
.close-modal:hover {
    background-color: #006837;
    cursor: pointer;
    color: white;
}

@media screen and (width <= 1100px) {
    .modal-picture {
        max-width: 600px;
    }
    .close-modal {
        padding: 7px 9px;
    }
    .modal-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #f1f1f1;
        padding: 40px 30px;
        border-radius: 3px;
        max-width: 1000px;
        min-width: 300px;
        margin-top: 150px;
    }

}
@media screen and (width <= 680px) {
    .modal-picture {
        max-width: 300px;
    }
    .close-modal {
        padding: 5px 7px;
    }
    .modal-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #f1f1f1;
        padding: 40px 30px;
        border-radius: 3px;
        max-width: 1000px;
        min-width: 300px;
        margin-top: 150px;
    }

}
