.Hero-Splash-Div {
  border-bottom: solid 9px #006837;
  border-top: solid 9px #006837;
  background-color: #258e46;
  width:100vw;
}

.SplashAboutJLD-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutPageImage {
  max-width: 1100px;
  min-width: 500px;
}

.AboutJLD-Info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
  min-width: 400px;
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  padding: 10px 0px;
}

.AboutJLDh3 {
  color: white;
  font-size: 50px;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 10px;
  /* margin-bottom: 20px; */
}

.learnMoreButton {
  font-weight: bold;
  background-color: white;
  border-radius: 50px 50px;
  padding: 10px 30px;
  font-size: 20px;
  color: #173c34;
  border: none;
  margin-top: 20px;
  transition-duration: 0.3s;
}

.learnMoreButton:hover {
  cursor: pointer;
  background-color: #173c34;
  color: white;
}

.about-p-tag {
  margin-left: 80px;
}
.twoImageContainer {
  display: none;
}

@media screen and (width <= 900px) {
  .SplashAboutJLD-Container {
    flex-direction: column;
    margin: 0px 0px;
  }
  .aboutPageImage {
    display: none;
    max-width: 200px;
    min-width: 40px;
  }
  .learnMoreButton {
    margin-bottom: 20px;
  }
  .about-p-tag {
    margin: 20px 10px;
    padding: 20px;
  }
  .twoImageContainer {
    display: flex;
    flex-direction: column;
  }

  .pictureAbout {
    margin: 10px 0px;
    max-width: 500px;
  }
}

@media screen and (width <= 375px) {
  .pictureAbout {
    max-width: 100vw;
  }
}
