.Splash-full-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  margin-top: 100px;
  background-color: #006837;
  isolation: isolate;
  background-image: url("./homePicture.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: solid 9px #006837;
}

.Information-Container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.company-name {
  font-size: 60px;
  font-weight: bolder;
  color: rgb(255, 255, 255);
}

.splashPhoto {
  width: 100vw;
  max-width: 900px;
}

.image-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: darkgreen;
  mix-blend-mode: multiply; */
  width: 100vw;
  height: 100vh;
}

.landscapePicture {
  width: 100vw;
  height: auto;
  max-width: 800px;
}

.free {
  margin: 0;
  font-weight: bold;
  display: inline;
}

.splashImage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.aboutJLD-h3 {
  color: white;
  font-size: 50px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.aboutJLD-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcomeDiv {
  background-color: rgba(4, 48, 28, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 70px;
  width: 100vw;
}

.quote-button {
  color: white;
  padding: 20px 40px;
  border-radius: 50px 50px;
  background-color: #006837;
  border: 2px solid #006837;
  transition-duration: 0.3s;
  font-weight: bold;
  font-size: 20px;
}
.quote-button:hover {
  cursor: pointer;
  border: 2px solid #258e46;
  background-color: #258e46;
}

.phoneNumberSplash {
  font-weight: bold;
  color: white;
  font-size: 20px;
}

.welcomeh3 {
  color: white;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 50px;
  margin-bottom: 10px;
}

.secondh3 {
  margin: 0px;
  margin-bottom: 40px;
}

@media screen and (width <= 900px) {
  .Splash-full-container {
    justify-content: center;

  }
  .welcomeDiv{
    /* display: inline; */
  }
  .splashImage-container {

  }
}
