.galleryh3 {
  color: #006837;
  font-size: 50px;
  margin-bottom: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.galleryPictures-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100vw;
}

.picture-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 500px;
}
.galleryPicture {
    max-width: 300px;
    max-height: 300px;
    margin: 10px 10px;
    border-radius: 5px;
}
.SplashGalleryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}


.galleryButton{
    border-radius: 50px 50px;
    padding: 20px 40px;
    background-color: #006837;
    color: white;
    font-size: 20px;
    transition-duration: .3s;
    font-weight: bold;

}
.galleryButton:hover{
cursor: pointer;
background-color: #258e46;
}


 @media screen and (width <= 900px){
.galleryPictures-container{
  max-width: 1000px;
}
.picture-container{
  flex-direction: column;
  max-width: 500px;
}
.div2{
  display: none;
}
.galleryPicture {
  max-width: 200px;

  margin: 5px 5px;
  border-radius: 5px;
}
.SplashGalleryContainer{
  margin-bottom: 20px;
}
}
